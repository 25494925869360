import request from '@/utils/request';
// 获取sig
export function getSig() {
  return request({
    url: '/system/userSig/getUserSig',
    method: 'get',
  });
}
export function getShareSig() {
  return request({
    url: '/system/userSig/getShareUserSig',
    method: 'get',
  });
}
