<template>
  <div class="login">
	  <div class="loginBox">
       <img src="../assets/images/login-f.png" class="login-logo" />
    <el-form ref="loginForm" :model="loginForm" class="login-form">
		<img src="../assets/images/logo.png" class="logo" alt="" srcset="">
      <el-tabs v-model="currentTab" class="center-tabs" :stretch="true" style="margin: 20px 50px 0px">
			<el-tab-pane label="手机号登录" name="mobile_login" />
            <el-tab-pane label="账号登录" name="account_login" />
      </el-tabs>
      <el-scrollbar class="right-scrollbar">
        <el-form v-show="currentTab === 'account_login'">
			<el-form-item class="f-item" prop="username" style="margin-top: 20px;">
			  <img src="../assets/images/icon-mobile.png" class="f-icon" />
			 <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="请输入用户名">
			 </el-input>
			</el-form-item>
          <el-form-item prop="password" class="f-item" style="margin-bottom: 40px;">
			<img src="../assets/images/icon-password.png" class="f-icon" />
            <el-input
              v-model="loginForm.password" type="password" auto-complete="off" placeholder="请输入密码"
             >
            </el-input>
          </el-form-item>

          <!-- <el-form-item v-if="captchaOnOff" prop="code">
            <el-input
              v-model="loginForm.code" auto-complete="off" placeholder="请输入验证码" style="width: 56%"
              @keyup.enter.native="handleLogin"
            >
            </el-input>

            <img src="../assets/images/login_icon_verify_code.png" class="left-icon" />

            <div class="login-code">
             <img :src="codeUrl" class="login-code-img" @click="getCode" />
            </div>
          </el-form-item> -->

          <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
          <el-form-item style="width:100%;">
            <el-button
              :loading="loading" size="medium" type="primary" style="width:100%;height: 60px;font-size:20px;"
              @click.native.prevent="accountLogin"
            >
              <span v-if="!loading">登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>

          </el-form-item>
        </el-form>


        <!-- 手机号登录 -->
        <el-form v-show="currentTab === 'mobile_login'" size="small">
          <el-form-item class="f-item" prop="username" style="margin-top: 20px;">
            <img src="../assets/images/icon-mobile.png" class="f-icon" />
            <el-input v-model="messageloginForm.phone" type="text" auto-complete="off" placeholder="请输入手机号">
            </el-input>
          </el-form-item>

          <el-form-item v-if="captchaOnOff" class="f-item" prop="code" style="margin-bottom: 40px;">
            <img src="../assets/images/icon-psw.png" class="f-icon" />
            <el-input
              v-model="messageloginForm.code" auto-complete="off" placeholder="请输入验证码" style="width: 100%"
              @keyup.enter.native="TomessageLogin"
            >
            </el-input>
            <!--
            <img src="../assets/images/login_icon_verify_code.png" class="left-icon" />
-->
            <div class="verify-code" @click="getVarifyCode">{{ varifyCodeText }}</div>
          </el-form-item>

          <el-form-item style="width:100%;">
            <el-button
              :loading="loading" size="medium" type="primary" style="width:100%;height: 60px;font-size:20px;"
              @click.native.prevent="TomessageLogin"
            >
              <span v-if="!loading">登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>
            <div v-if="register" style="float: right;">
              <router-link class="link-type" :to="'/register'">立即注册</router-link>
            </div>
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </el-form>
	</div>
    <!--  底部  -->
    <div class="el-login-footer">
      <span> </span>
    </div>
  </div>
</template>

<script>
import { getToken, setToken, removeToken } from '@/utils/auth';
import {getInfo, getTelCode, login, messagelogin} from '@/api/login';
import {Message} from "element-ui";


export default {
  name: 'Login',
  data() {
    return {
      currentTab: 'mobile_login',
      varifyCodeText: '获取验证码',
      // 是否已经点击过获取验证码
      isTrigger: false,
      codeUrl: '',
      loginForm: {
        username: '',
        password: '',
        rememberMe: false,
        code: '',
        uuid: '',
      },
      messageloginForm: {
        phone: '',
        code: '',
      },
      loading: false,
      // 验证码开关
      captchaOnOff: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  watch: {
  },
  created() {
    // this.getCode();
    removeToken();
    // this.getCookie();
  },
  methods: {

    getVarifyCode() {
      if (this.isTrigger) return ;
      getTelCode(this.messageloginForm).then((res) => {
        if (res.data.data) {
          Message.success('发送成功');
        }
      });
      let n = 60;
      const inval = setInterval(
        () => {
          if (n === 0) {
            this.varifyCodeText = '重新获取验证码';
            this.isTrigger = false;
            clearInterval(inval);
          } else {
            this.isTrigger = true;
            // eslint-disable-next-line no-plusplus
            this.varifyCodeText = `${n--}秒后重发`;
          }
        },
        1000,
      );
    },
    handleLogin() {
      // setToken('123');
      // eslint-disable-next-line eqeqeq
      if (getToken() != null) {
        // alert('12');
        this.$router.push({
          path: 'home',
          query: {
          },
        });
      }
    },

    TomessageLogin() {
      this.loading = true;

      const username = this.messageloginForm.phone;
      const { code } = this.messageloginForm;
      return new Promise((resolve, reject) => {
        messagelogin(username, code).then((res) => {
          setToken(res.token);
          this.getUserInfo();
          // commit('SET_TOKEN', res.token);

          if (this.$route.query.invited !== undefined) {
            window.localStorage.getItem('invite');
            location.href = window.localStorage.getItem('invite');
            // this.$router.push({
            //   path: 'home',
            //   query: {
            //   },
            // });
          }  else if (this.$route.query.beformed !== undefined) {
            window.localStorage.getItem('beform');
            location.href = window.localStorage.getItem('beform');
          } else if (getToken() !== null) {
            this.$router.push({
              path: 'home',
              query: {
              },
            });
          }
          // resolve();

          // eslint-disable-next-line eqeqeq
/*           if (getToken() != null) {
            this.$router.push({
              path: 'Im',
              query: {
              },
            });
          } */
        })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    accountLogin() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        login(this.loginForm.username, this.loginForm.password, null, null).then((res) => {
          setToken(res.token);
          this.getUserInfo();
          // commit('SET_TOKEN', res.token);

          if (this.$route.query.invited !== undefined) {
            window.localStorage.getItem('invite');
            location.href = window.localStorage.getItem('invite');
            // this.$router.push({
            //   path: 'home',
            //   query: {
            //   },
            // });
          } else if (this.$route.query.beformed !== undefined) {
            window.localStorage.getItem('beform');
            location.href = window.localStorage.getItem('beform');
          } else if (getToken() !== null) {
            this.$router.push({
              path: 'home',
              query: {
              },
            });
          }
          // resolve();

          // eslint-disable-next-line eqeqeq
/*           if (getToken() != null) {
            this.$router.push({
              path: 'Im',
              query: {
              },
            });
          } */
        })
          .catch((error) => {
            this.loading = false;
            reject(error);
          });
      });
    },
    getUserInfo() {
      getInfo().then((res) => {
        const { user } = res;
        console.log(user);
      });
    },

  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
	.el-tabs__item{
		font-size:16px!important;
	}
	.logo{
		width: 230px;height:80px;display:block;margin:0 auto;
	}
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #2d3a4b;
    background: url("../assets/images/bg-login.png") no-repeat;
    background-size: cover;
  }
.loginBox{
	margin: 0 auto;
	display:flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 8px 18px 0px rgba(111,124,140,0.15);
	border-radius:20px;
	overflow: hidden;
}
  .login-logo {
    width:690px;
	height:620px;
  }

  .login-form {
    width: 525px;
	height:620px;
    padding: 80px 60px;
	box-sizing: border-box;
    // opacity: 0.9;
    border: 2px solid #FFFFFF;
	border-radius:0 20px 20px 0;
    background: linear-gradient(0deg, #FFFFFF 0%, #EBF2FD 100%);

    .f-item {
      .el-form-item__content {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 20px;
      box-sizing: border-box;
      border-radius: 10px;
      overflow: hidden;
      background-color: #fff;
      }
    }
    .f-icon {
      width: 24px;
      height: 24px;
    }

    .el-input {
      input {
        border: none !important;
        height: 60px !important;
      }
    }

    .input-icon {
      height: 45px;
      width: 14px;
      margin-left: 2px;
    }

    .el-input__inner {
      border-radius: 0px;
      /*输入框圆角值*/
      padding-left: 60px;
      display: flex;

      -webkit-appearance: none;
      background-color: #FFFFFF !important;
      background-image: none !important;
      border-radius: 4px;
      border: 1px solid #DCDFE6 !important;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: none;
      padding: 0 15px;
      -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }

    box-shadow: 0px 0px 24px 0px rgba(218, 225, 234, 0.7000);
  }

  .left-icon {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 43px;
    height: 43px;
  }

  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }

  .el-form-item__content {
    display: flex;
  }
  .login-code {
    width: 33%;
    height: 43px;
    margin-left: 4%;
    float: right;
    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .verify-code {
    width: 33%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #5A9DF8;
    font-size: 16px;
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #95A5AD;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .login-code-img {
    height: 43px;
  }
  /*隐藏tab下面的一横*/
  .el-tabs .el-tabs__nav-wrap::after {
    background-color: white;
  }
  .el-button--primary {
    background-color: #409EFF;
    //height: 42px;
  }

</style>
