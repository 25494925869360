import request from '@/utils/request';

// 查询PPT文件上传记录详细
export function getFile(id) {
  return request({
    url: `/tuiroom/file/${id}`,
    method: 'get',
  });
}
// 新增PPT文件上传记录
export function addFile(data) {
  return request({
    url: '/tuiroom/file/saveApi',
    method: 'post',
    data,
  });
}
// 根据房间号和用户id查询PPT文件上传记录详细
export function getUserFile(roomId, userId) {
  const data = {
    roomId,
    userId,

  };
  return request({
    url: '/conferenceApi/content/queryRoomPpt',
    method: 'get',
    params: data,
  });
}

export function getUserAuth(roomId, userId) {
  const data = {
    roomId,
    userId,

  };
  return request({
    url: '/conferenceApi/content/getAuth',
    method: 'get',
    params: data,
  });
}
/* 根据房间号查会议*/
export function gmConferenceInfo(roomId) {
  const data = {
    roomId,
  };
  return request({
    url: '/conferenceApi/content/gmConferenceInfo',
    method: 'get',
    params: data,
  });
}

/* 查询上传的ppt列表*/

export function queryRoomPpt(roomId, userId) {
  const data = {
    roomId,
    userId,

  };
  return request({
    url: '/conferenceApi/content/queryRoomPpt',
    method: 'get',
    params: data,
  });
}

/* 查询上传的ppt列表*/
export function queryChangeRoomPpt(roomId) {
  const data = {
    roomId,
  };
  return request({
    url: '/conferenceApi/content/queryChangeRoomPpt',
    method: 'get',
    params: data,
  });
}

/* 切换显示ppt操作*/
export function changeRoomPpt(roomId, pptId, type) {
  const data = {
    roomId, pptId, type,
  };
  return request({
    url: '/conferenceApi/content/changePPT',
    method: 'get',
    params: data,
  });
}

/* 查看有无会议及当前登录人有无权限*/
export function getRoomUser(roomId, userId) {
  const data = {
    roomId, userId,
  };
  return request({
    url: '/conferenceApi/content/getRoomUser',
    method: 'get',
    params: data,
  });
}

/* 更改会议端状态roomOnState*/
export function getChangeRoomOnState(roomId, roomOnState, confState) {
  const data = {
    roomId, roomOnState, confState,
  };
  return request({
    url: '/conferenceApi/content/getChangeRoomOnState',
    method: 'get',
    params: data,
  });
}
// 根据房间号 PPT文件上传是否成功
export function getppttemp(code) {
  const data = {
    code,
  };
  return request({
    url: '/conferenceApi/content/getppttemp',
    method: 'get',
    params: data,
  });
}
