import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home.vue';
import pcToHome from '../views/pcToHome.vue';
import login from '../views/login.vue';
import Im from '../views/Im.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {

      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆

    },
    component: login,
  },
  {
    path: '/',
    name: 'login',
    component: login,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },  {
    path: '/pcToHome',
    name: 'pcToHome',
    component: pcToHome,
  },
  {
    path: '/dashboard/index',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "about" */ '../views/test.vue'),
  },  {
    path: '/test2/test2',
    name: 'test2',
    component: () => import(/* webpackChunkName: "about" */ '../views/test2/test2.vue'),
  },
  {
    path: '/Im',
    name: 'Im',
    meta: {

      requireAuth: true, // 配置此条，进入页面前判断是否需要登陆

    },
    component: Im,
  },

  {
    path: '/room',
    name: 'room',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/room.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;

export function useRouter() {
  return  router;
}

export function useRoute() {
  return router.currentRoute;
}
