import Vue from 'vue';
import App from './App.vue';
import router  from './router';
import { createPinia, PiniaVuePlugin } from 'pinia';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { getToken, getUrlKey, removeToken } from '@/utils/auth';
import SvgIcon from '@/components/SvgIcon';
import FileUpload from '@/components/FileUpload';
import plugins from '@/plugins'; // plugins

Vue.use(PiniaVuePlugin);
Vue.use(plugins);
const pinia = createPinia();
Vue.component('SvgIcon', SvgIcon);
Vue.component('FileUpload', FileUpload);

Vue.use(ElementUI);

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  // getUrlKey("url");
  console.log(getUrlKey('invite'));
  const  invite = getUrlKey('invite');
  console.log(getUrlKey('beform'));
  const  beform = getUrlKey('beform');
  console.log('--------------------------');

   // debugger;
  if (to.path.startsWith('/login')) {
    // window.sessionStorage.removeItem('Token');
    removeToken();
    next();
    // eslint-disable-next-line eqeqeq
  } else if (invite !== undefined && invite != null) {
    const user = getToken();
    window.localStorage.setItem('invite', window.location.href);
    if (!user) {
      next({
        path: '/login',
        query: { invited: invite },
      });
    }  else {
      next();
    }
  } else if (beform !== undefined && beform != null) {
    const user = getToken();
    window.localStorage.setItem('beform', window.location.href);
    if (!user) {
      next({
        path: '/login',
        query: { beformed: beform },
      });
    }  else {
      next();
    }
  } else {
    // const user = window.sessionStorage.getItem('Token');
    const user = getToken();

    // debugger;
    if (!user) {
      next({
        path: '/login',
      });
    }  else {
      next();
    }
  }
});
new Vue({
  pinia,
  router,
  render: h => h(App),
}).$mount('#app');
