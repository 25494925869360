import Cookies from 'js-cookie';

const TokenKey = 'Admin-Token-Conference';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}


export function getUrlKey(name) {
  return decodeURIComponent((new RegExp(`[?|&]${name}=` + '([^&;]+?)(&|#|;|$)').exec(location.href) || [undefined, ''])[1].replace(/\+/g, '%20')) || null;
}
