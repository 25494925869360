import { defineStore } from 'pinia';
// 定义并导出容器，第一个参数是容器id，必须唯一，用来将所有的容器
// 挂载到根容器上
export const gmAuthStore = defineStore('gmAuthStore',{
  // 定义state，用来存储状态的
  state:()=>{
    return {
      test:false,
      instruct:null,//登录后在home里进行设置
      gmUserInfo:{//登录后在home里进行设置
        roomId:null,
        userId:null,
      },
      canvasAuth:{//登录后在home里进行设置
        isDrawingMode:false,
      },
      chatAuth:{//登录后在home里进行设置
        isChatMode:true,
      },
      gmCanvasMuted:false,
      gmChatMuted:false,


    }
  },
  // 定义getters，类似于computed，具有缓存g功能
  getters:{

  },
  // 定义actions，类似于methods，用来修改state，做一些业务逻辑
  actions:{
    changeState(e:any){
      this.instruct=e;
    },
    setInstruct(e:any){
      sessionStorage.setItem('instruct', e);
      this.instruct=e;
    },
    getInstruct (){
      //alert(sessionStorage.getItem('instruct'))
      return this.instruct==null?sessionStorage.getItem('instruct'):this.instruct;
    },
    removeInstruct (){
      sessionStorage.removeItem('instruct');
    },
    setUserInfo(roomId:any , userId:any){
      this.gmUserInfo.roomId=roomId;
      this.gmUserInfo.userId=userId;
      sessionStorage.setItem('gmUserInfo', JSON.stringify(this.gmUserInfo));

    },
    getUserInfo(){
      if(this.gmUserInfo.userId!=null&&this.gmUserInfo.roomId!=null){
        return this.gmUserInfo;
      }else {
        return sessionStorage.getItem('gmUserInfo')
      }

    },
    // 获取用户的权限 跟 instruct=goOn 配合用 如果是正常从后台加的会议 那么就用 getGmUserAuth获取权限来用 如果这个会议是直接登录会议端创建的那么浏览器缓存里的（instruct=goOn）这个goOn就是关键
    getGmUserAuth(){
      let userConferenceAuth:any=window.localStorage.getItem('userConferenceAuth');
      if(userConferenceAuth !== null && userConferenceAuth !== 'null'){
        return  userConferenceAuth=JSON.parse(userConferenceAuth);
      }else {
        return null;
      }

    },
    getCanvasAuth(){
      let usercanvasAuth:any=sessionStorage.getItem('CanvasAuth');
      if(usercanvasAuth !== null && usercanvasAuth !== 'null'){
          usercanvasAuth=JSON.parse(usercanvasAuth);
        return usercanvasAuth.isDrawingMode;
      }else {
        return this.canvasAuth.isDrawingMode;
      }
    },
    getChatAuth(){
      let userChatAuth:any=window.localStorage.getItem('ChatAuth');
      if(userChatAuth !== null && userChatAuth !== 'null'){
          userChatAuth=JSON.parse(userChatAuth);
        return userChatAuth.isChatMode;
      }else {
        return this.chatAuth.isChatMode;
      }
    },
    setCanvasAuth(flag:any){
      console.log("--flag--")
      console.log(flag)
      if(flag!=null){
        this.canvasAuth.isDrawingMode=flag
        sessionStorage.setItem('CanvasAuth', JSON.stringify(this.canvasAuth));
      }else {
        //判断有没有userConferenceAuth如果有那么就判断当前是什么角色来进行控制
       let Auth :any =this.getGmUserAuth();
        if(Auth){
          if(Auth.authKey === 'confAdmin'){
            //管理员
            this.canvasAuth.isDrawingMode=true
          }else if(Auth.authKey === 'confHost') {
            //会议主持人
            this.canvasAuth.isDrawingMode=true
          }else if(Auth.authKey === 'confSpeaker') {
            // 会议讲者
            this.canvasAuth.isDrawingMode=false
          }else if(Auth.authKey === 'confOpenUser') {
            // 开放用户
            this.canvasAuth.isDrawingMode=false
          }
        } else if(this.getInstruct()==='goOn') {
          this.canvasAuth.isDrawingMode=true
        }else {
          this.canvasAuth.isDrawingMode=false
        }

      }
      sessionStorage.setItem('CanvasAuth', JSON.stringify(this.canvasAuth));

    },
    setChatAuth(flag:any){
      this.chatAuth.isChatMode=flag
      sessionStorage.setItem('ChatAuth', JSON.stringify(this.chatAuth));
    },
    setGmCanvasMuted(userId: string,muted:any){
      this.gmCanvasMuted=muted
    },
    setGmChatMuted(userId: string,muted:any){
      this.gmChatMuted=muted
    }


  }
})
