/*
 * @Description: TUIRoom 应用的基础信息配置
 */

// import LibGenerateTestUserSig from './lib-generate-test-usersig-es.min';
import request from '@/utils/request';
import { getShareSig, getSig } from '@/api/sig';
import {getUserAuth} from "@/api/bussines";
/**
 * Tencent Cloud SDKAppId, which should be replaced with user's SDKAppId.
 * Enter Tencent Cloud TRTC [Console] (https://console.cloud.tencent.com/trtc ) to create an application,
 * and you will see the SDKAppId.
 * It is a unique identifier used by Tencent Cloud to identify users.
 *
 * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
 * 它是腾讯云用于区分客户的唯一标识。
 */

export const SDKAPPID = 1400788482;

/**
 * Encryption key for calculating signature, which can be obtained in the following steps:
 *
 * Step1. Enter Tencent Cloud TRTC [Console](https://console.cloud.tencent.com/rav ),
 * and create an application if you don't have one.
 * Step2. Click your application to find "Quick Start".
 * Step3. Click "View Secret Key" to see the encryption key for calculating UserSig,
 * and copy it to the following variable.
 *
 * Notes: this method is only applicable for debugging Demo. Before official launch,
 * please migrate the UserSig calculation code and key to your backend server to avoid
 * unauthorized traffic use caused by the leakage of encryption key.
 * Document: https://intl.cloud.tencent.com/document/product/647/35166#Server
 *
 * 计算签名用的加密密钥，获取步骤如下：
 *
 * step1. 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav )，如果还没有应用就创建一个，
 * step2. 单击“应用配置”进入基础配置页面，并进一步找到“帐号体系集成”部分。
 * step3. 点击“查看密钥”按钮，就可以看到计算 UserSig 使用的加密的密钥了，请将其拷贝并复制到如下的变量中
 *
 * 注意：该方案仅适用于调试Demo，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。
 * 文档：https://cloud.tencent.com/document/product/647/17275#Server
 */
export const SECRETKEY = 'd16bdb1d55d0b4896166c6550a68430c946585b892d826d9f0806807fb7a0b7f';
/**
 * Signature expiration time, which should not be too short
 * Time unit: second
 * Default time: 7 * 24 * 60 * 60 = 604800 = 7days
 *
 * 签名过期时间，建议不要设置的过短
 * 时间单位：秒
 * 默认时间：7 x 24 x 60 x 60 = 604800 = 7 天
 */
export const EXPIRETIME = 604800;

/**
 * Set user information on the push side
 * Note: The web side screen sharing stream and audio/video stream are two Clients,
 * the screen sharing stream user id is `share_${userId}`
 *
 * 设置推流端用户信息
 * 注意：web端屏幕分享流和音视频流为两个Client, 屏幕分享流用户id为`share_${userId}`
 */
export const userInfo = {
  // 用户Id
  userId: `user_${Math.ceil(Math.random() * 100000)}`,
  // 用户昵称
  userName: 'myName',
  // 用户头像
  userAvatar: '',
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let userSig = '';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let shareUserSig = '';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let shareUserId = '';
let userId = '';
let userName = '';
export function getBasicInfo() {
  // debugger;
  const userAvatar = '';
  if (SDKAPPID === Number(0) || SECRETKEY === String('')) {
    alert('Please configure your SDKAPPID in config/basic-info-config.js');
    return;
  }
  // const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
  // const userSig = generator.genTestUserSig(userInfo.userId);
  // const shareUserId = `share_${userInfo.userId}`;
  // const shareUserSig = generator.genTestUserSig(shareUserId);
  // const { userId, userName, userAvatar } = userInfo;
  console.log('---------------->');
  // console.log(generator);
  console.log(SDKAPPID);
  console.log(userId);
  console.log(shareUserId);
  console.log(userSig);
  console.log(shareUserSig);
  console.log(userName);
  console.log(userAvatar);
  console.log('------------------^');
  return {
    sdkAppId: SDKAPPID,
    userId,
    shareUserId,
    userSig,
    shareUserSig,
    userName,
    userAvatar,
  };
}


// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get',
  });
}


/* export function getBasicInfo() {
  if (SDKAPPID === Number(0) || SECRETKEY === String('')) {
    alert('Please configure your SDKAPPID in config/basic-info-config.js');
    return;
  }
  const generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
  const userSig = generator.genTestUserSig(userInfo.userId);
  const shareUserId = `share_${userInfo.userId}`;
  const shareUserSig = generator.genTestUserSig(shareUserId);
  const { userId, userName, userAvatar } = userInfo;
  console.log('---------------->');
  console.log(generator);
  console.log(SDKAPPID);
  console.log(userId);
  console.log(shareUserId);
  console.log(userSig);
  console.log(shareUserSig);
  console.log(userName);
  console.log(userAvatar);
  console.log('------------------^');
  return {
    sdkAppId: SDKAPPID,
    userId,
    shareUserId,
    userSig,
    shareUserSig,
    userName,
    userAvatar,
  };
}; */
export async function getUserSig() {
  await getSig()
    .then((res) => {
      console.log(res);
      userSig = res.msg;
    });
};
export async function getShareUserSig() {
  await getShareSig()
    .then((res) => {
      console.log(res.data);
      shareUserSig = res.data.shareUserSig;
      shareUserId = res.data.shareUserId;
    });
}

export async function setInfo() {
  await getInfo()
    .then((res) => {
      if (res) {
        const { user } = res;
        userName = user.nickName;
        userId = user.userId.toString();
      }
    });
}


export async function getAuth(roomId, userId) {
  await  getUserAuth(roomId, userId).then(res=>{
    if (res.code === 200) {
      if (res.userConferenceAuth) {
        window.localStorage.setItem('userConferenceAuth', JSON.stringify(res.userConferenceAuth));
      } else {
        alert('没有权限！');
      }
    }
  });
}


