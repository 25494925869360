<template>
  <div class="home-container">
    <div class="header">
      <user-info
        class="header-item user-info"
        :user-id="userId"
        :user-name="userName"
        :user-avatar="userAvatar"
        @log-out="handleLogOut"
      ></user-info>
    </div>
    <stream-preview ref="streamPreviewRef"></stream-preview>
    <room-control
      :given-room-id="givenRoomId"
      @create-room="handleCreateRoom"
      @enter-room="handleEnterRoom"
    ></room-control>
  </div>
</template>

<script>
import UserInfo from '@/TUIRoom/components/RoomHeader/UserInfo.vue';
import StreamPreview from '@/TUIRoom/components/RoomHome/StreamPreview.vue';
import RoomControl from '@/TUIRoom/components/RoomHome/RoomControl.vue';
import TUIRoomCore from '@/TUIRoom/tui-room-core';
import { getAuth, getBasicInfo, getShareUserSig, getUserSig, setInfo } from '@/config/basic-info-config';
import { logout } from '@/api/login';
import { removeToken } from '@/utils/auth';
import { getRoomUser } from '@/api/bussines';
import { gmAuthStore } from '@/TUIRoom/stores/gmauth';
// import { getShareSig, getSig } from '@/api/sig';
// import { getShareSig, getSig } from '@/api/sig';
export default {
  name: 'Home',
  components: { UserInfo, StreamPreview, RoomControl },
  data() {
    return {
      givenRoomId: '',
      basicInfo: null,
      userName: '',
      userAvatar: '',
      userId: '',
      userSig: '',
      shareUserSig: '',
      shareUserId: '',
    };
  },
  // beforeCreate() {
  //    // alert("1111");
  //    this.getShareUserSig();
  //    this.getUserSig();
  //    this.setInfo();
  //  },
  async mounted() {
    // alert("22222");

    // this.getUserSig();
    // this.getShareUserSig();

    await  getShareUserSig();
    await  getUserSig();
    await  setInfo();
    this.givenRoomId = this.$route.query.roomId || '';
    this.basicInfo =   getBasicInfo();
    this.userName = this.basicInfo.userName;
    this.userAvatar = this.basicInfo.userAvatar;
    this.userId = this.basicInfo.userId;
    if (this.basicInfo) {
      await this.getRoomUserInfo(this.givenRoomId, this.userId);
      sessionStorage.setItem('tuiRoom-userInfo', JSON.stringify(this.basicInfo));
      const { sdkAppId, userId, userSig } = this.basicInfo;
      // 登录 TUIRoomCore, 只有登录 TUIRoomCore 之后，才可以使用 TUIRoomCore.checkRoomExistence 方法
      TUIRoomCore.login(sdkAppId, userId, userSig);
    }
  },
  methods: {


    setTUIRoomData(action, mode) {
      const roomParam = this.$refs.streamPreviewRef.getRoomParam();
      const roomData = {
        action,
        roomMode: mode || 'FreeSpeech',
        roomParam,
      };
      console.log('roomData');
      console.log(roomData);
      sessionStorage.setItem('tuiRoom-roomInfo', JSON.stringify(roomData));
    },
    // 创建房间时生成房间号
    async generateRoomId() {
      const roomId = Math.ceil(Math.random() * 1000000);
      const isRoomExist = await TUIRoomCore.checkRoomExistence(roomId);
      if (isRoomExist) {
        return await this.generateRoomId();
      }
      return roomId;
    },
    // 处理点击【创建房间】
    async handleCreateRoom(mode) {
      this.setTUIRoomData('createRoom', mode);
      const roomId = await this.generateRoomId();
      this.$router.push({ path: 'room', query: { roomId } });
    },
    // 处理点击【进入房间】
    async handleEnterRoom(roomId) {
      console.log('roomId');
      console.log(roomId);

      const isRoomExist = await TUIRoomCore.checkRoomExistence(roomId);
      if (!isRoomExist) {
        alert('房间不存在，请确认房间号或创建房间！');
        return;
      }
      // 如果正在开的会议 就判断当前人有没有权限
      await this.intoRoom(roomId, this.userId);
    },
    // 处理用户点击页面左上角【退出登录】
    handleLogOut() {
      // alert("11");
      // 接入方处理 logout 方法
      logout().then((res) => {
        if (res.code === 200) {
          removeToken();
          this.$router.push({
            path: 'login',
            query: {
            },
          });
        }
      });
    },
    async getRoomUserInfo(roomId, userId) {
      await getRoomUser(roomId, userId).then(async (res) => {
        console.log('-----------------------------');
        console.log(res);
        if (res.code === 200) {
          if (res.data.instruct === 'exist') {
            await getAuth(this.givenRoomId, this.userId);
          } else if (res.data.instruct === 'no') {
            await this.$router.push({
              path: 'login',
              query: {},
            });
          } else {

          }
          // 向gmAuthStore放置权限
          const firstStore = gmAuthStore();
          firstStore.setInstruct(res.data.instruct);
          firstStore.setCanvasAuth();
        }
      });
    },
    async intoRoom(roomId, userId) {
      await getRoomUser(roomId, userId).then(async (res) => {
        console.log('-----------------------------');
        console.log(res);
        if (res.code === 200) {
          if (res.data.instruct === 'exist') {
            await getAuth(roomId, this.userId);
            await this.setTUIRoomData('enterRoom');
            await this.$router.push({
              path: 'room',
              query: {
                roomId,
              },
            });
          } else if (res.data.instruct === 'no') {
            alert('没有此房间的权限！');
            return;
          } else if (res.data.instruct === 'goOn') {
            await this.setTUIRoomData('enterRoom');
            await this.$router.push({
              path: 'room',
              query: {
                roomId,
              },
            });
          }
        }
      });
    },
    // async  getUserSig() {
    //   await  getSig().then((res) => {
    //     console.log(res);
    //     this.userSig = res.msg;
    //   });
    // },
    // async getShareUserSig() {
    //   await getShareSig().then((res) => {
    //     console.log(res.data);
    //     this.shareUserSig = res.data.shareUserSig;
    //     this.shareUserId = res.data.shareUserId;
    //   });
    // },
    //
    // async setInfo() {
    //   await getInfo().then((res) => {
    //     if (res) {
    //       const { user } = res;
    //       this.userName = user.nickName ;
    //       this.userId = user.userId.toString() ;
    //     }
    //   });
    // },


  },
};
</script>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  height: 100%;
  background-color: #010101;
  color: #B3B8C8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Medium;
  .header {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 22px 24px;
    display: flex;
    align-items: center;
    .header-item {
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
}
</style>
